<template>
    <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                small
                v-bind="attrs"
                v-on="on"
                color="red"
                outlined
                class="text-none"
            ><v-icon left>mdi-delete-outline</v-icon>Delete</v-btn>
        </template>
        <v-card rounded flat>
            <v-card-title class="text-h6 primary">Delete peering node {{ node.name }}?</v-card-title>
            <v-card-text class="mt-8 body-1">Are you sure you want to delete this peering node?</v-card-text>
            <v-divider />
            <v-card-actions>
                <v-btn width="50%" rounded color="red" text @click="doDelete">Yes</v-btn>
                <v-btn width="50%" rounded color="green" text @click="close">No</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { ref } from "@vue/composition-api";
import axios from "axios";

export default {
    props: ["peer", "node"],
    components: {},
    setup(props) {
        const dialog = ref(false);
        const errorMessages = ref("");
        const successMessages = ref("");

        function doDelete() {
            axios
                .delete(`/api/v1/peers/${props.peer.guid}/nodes/${props.node.guid}`)
                .then(() => {
                    location.reload();
                })
                .catch((error) => {
                    errorMessages.value = error.response.data.detail;
                });
        }

        function close() {
            dialog.value = false;
            errorMessages.value = "";
            successMessages.value = "";
        }

        return {
            dialog,
            errorMessages,
            successMessages,
            doDelete,
            close,
        };
    },
};
</script>
