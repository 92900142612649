<template>
    <div>
        <TierLimitReached :snackbar="snackbar" />
        <MyCard>
            <template v-slot:header>
                <span v-if="newPeer">New BGP Peer</span>
                <span v-else>BGP Peer {{ model_peer.name }} ({{ model_peer.ip }})</span>
                <v-spacer />

                <div class="float-right">
                    <v-btn small class="text-none" :disabled="!changed" @click="save" color="green" outlined><v-icon small left>mdi-content-save-outline</v-icon>Save</v-btn>
                    <v-btn small class="text-none" :disabled="newPeer || !changed" @click="revert" color="red" outlined><v-icon small left>mdi-undo</v-icon>Revert</v-btn>
                    <v-btn small class="text-none" :disabled="newPeer" @click="$router.push(`/templates/${model_peer.guid}`)" outlined><v-icon small left>mdi-file-document-outline</v-icon>Template</v-btn>
                    <PeerDelete :disabled="newPeer" :peer="peer" />
                </div>

                <v-btn-toggle v-if="false" dense rounded class="float-right">
                    <v-btn :disabled="!changed" @click="Save" color="green" class="text-none">Save</v-btn>
                    <v-btn :disabled="!changed" @click="Revert" color="red" class="text-none">Revert</v-btn>
                    <v-btn @click="$router.push(`/templates/${model_peer.guid}`)" class="text-none">Template</v-btn>
                    <PeerDelete :peer="peer" />
                </v-btn-toggle>
            </template>
            <v-container>
                <v-row>
                    <v-col cols="4">
                        <div>
                            <div class="text-subtitle-2 mt-2">Name<v-icon name="name" v-if="!isEditable.name" @click="clickEdit($event)" x-small>mdi-pencil</v-icon></div>
                            <v-text-field
                                :readonly="!isEditable.name"
                                @change="change"
                                v-model="model_peer.name"
                                hint=""
                                persistent-hint
                                :error-messages="messages.name"
                                class="no-line mt-0 pt-0"
                                tabindex="1"
                                :autofocus="newPeer"
                                @input="input"
                            />
                        </div>
                        <div>
                            <div class="text-subtitle-2 mt-2">MD5 authentication</div>
                            <v-text-field
                                :disabled="true"
                                :readonly="true"
                                hint=""
                                persistent-hint
                                class="no-line mt-0 pt-0"
                                tabindex="3"
                                value="mandatory"
                            />
                        </div>
                        <div>
                            <div class="text-subtitle-2 mt-2">BGP ASN (customer)<v-icon name="asnPeer" v-if="!isEditable.asnPeer" @click="clickEdit($event)" x-small>mdi-pencil</v-icon></div>
                            <v-text-field
                                :readonly="!isEditable.asnPeer"
                                @change="change"
                                v-model="model_peer.asnPeer"
                                hint=""
                                persistent-hint
                                :error-messages="messages.asnPeer"
                                class="no-line mt-0 pt-0"
                                tabindex="3"
                                @input="input"
                            />
                        </div>
                        <div>
                            <div class="text-subtitle-2 mt-2">IPv4 Discarding next-hop<v-icon name="nextHopIPv4" v-if="!isEditable.nextHopIPv4" @click="clickEdit($event)" x-small>mdi-pencil</v-icon></div>
                            <v-text-field
                                :readonly="!isEditable.nextHopIPv4"
                                @change="change"
                                v-model="model_peer.nextHopIPv4"
                                hint="IPv4 prefixes have next hop set to this IPv4 address" persistent-hint
                                :error-messages="messages.nextHopIPv4"
                                class="no-line mt-0 pt-0"
                                tabindex="5"
                                @input="input"
                            />
                        </div>
                        <div>
                            <div class="text-subtitle-2 mt-2">Receive IPv4 feed</div>
                            <v-switch @change="changed = true" color="primary" v-model="model_peer.receiveIPv4" class="mt-0 pt-0" tabindex="7"></v-switch>
                        </div>
                    </v-col>
                    <v-col cols="4">
                        <div>
                            <div class="text-subtitle-2 mt-2">IP Address<v-icon name="ip" v-if="!isEditable.ip" @click="clickEdit($event)" x-small>mdi-pencil</v-icon></div>
                            <v-text-field
                                :readonly="!isEditable.ip"
                                @change="change"
                                v-model="model_peer.ip"
                                hint=""
                                persistent-hint
                                :error-messages="messages.ip"
                                class="no-line mt-0 pt-0"
                                tabindex="2"
                                @input="input"
                            />
                        </div>
                        <div>
                            <div class="text-subtitle-2 mt-2">BGP Password<v-icon name="bgpPassword" v-if="!isEditable.bgpPassword" @click="clickEdit($event)" x-small>mdi-pencil</v-icon></div>
                            <v-text-field
                                :readonly="!isEditable.bgpPassword"
                                @change="change"
                                v-model="model_peer.bgpPassword"
                                hint=""
                                persistent-hint
                                :error-messages="messages.bgpPassword"
                                class="no-line mt-0 pt-0"
                                tabindex="4"
                                :type="passwordVisible ? 'text' : 'password'"
                                @input="input"
                            >
                                <template v-slot:prepend>
                                    <v-icon @click="passwordVisible = !passwordVisible" v-if="passwordVisible">mdi-eye-off</v-icon>
                                    <v-icon @click="passwordVisible = !passwordVisible" v-else>mdi-eye</v-icon>
                                </template>
                            </v-text-field>
                        </div>
                        <div>
                            <div class="text-subtitle-2 mt-2">BGP ASN (Network Black Hole)<v-icon name="asnLocal" v-if="!isEditable.asnLocal" @click="clickEdit($event)" x-small>mdi-pencil</v-icon></div>
                            <v-text-field
                                :readonly="!isEditable.asnLocal"
                                @change="change"
                                v-model="model_peer.asnLocal"
                                hint=""
                                persistent-hint
                                :error-messages="messages.asnLocal"
                                class="no-line mt-0 pt-0"
                                tabindex="3"
                                @input="input"
                            />
                        </div>
                        <div>
                            <div class="text-subtitle-2 mt-2">IPv6 Discarding next-hop<v-icon name="nextHopIPv6" v-if="!isEditable.nextHopIPv6" @click="clickEdit($event)" x-small>mdi-pencil</v-icon></div>
                            <v-text-field
                                :readonly="!isEditable.nextHopIPv6"
                                @change="change"
                                v-model="model_peer.nextHopIPv6"
                                hint="IPv6 prefixes have next hop set to this IPv6 address"
                                persistent-hint
                                :error-messages="messages.nextHopIPv6"
                                class="no-line mt-0 pt-0"
                                tabindex="6"
                                @input="input"
                            />
                        </div>
                        <div>
                            <div class="text-subtitle-2 mt-2">Receive IPv6 feed</div>
                            <v-switch @change="changed = true" color="primary" v-model="model_peer.receiveIPv6" class="mt-0 pt-0" tabindex="8" />
                        </div>
                    </v-col>
                    <v-col cols="4">
                        <div class="text-subtitle-2">Profile</div>
                        <v-select
                            return-object
                            item-text="name"
                            item-value="guid"
                            @change="changed = true"
                            :items="profiles"
                            v-model="profile"
                            tabindex="9"
                            no-data-text="you have no profiles"
                        />
                        <!-- filtersLoading: {{ filtersLoading }} -->
                        <div v-if="!filtersLoading && profile" class="transparent">
                            <div class="text-subtitle-2">Enabled Filters</div>
                            <v-progress-circular v-if="filtersLoading" indeterminate color="primary" />
                            <!-- <v-progress-circular v-if="filtersLoading && newPeer" indeterminate color="primary" /> -->
                            <v-list
                                dense
                                v-if="Object.keys(profile).length !== 0"
                                class="rounded-lg scrollable"
                                max-height="300"
                            >
                                <v-list-item dense v-for="item in profile.filters" :key="item.guid">
                                    <!-- {{ item }} -->
                                    <v-list-item-title>{{ item.type }} - {{ item.name }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </MyCard>
        <MyCard v-if="!newPeer" class="my-4">
            <template v-slot:header>
                Peering Nodes
                <v-spacer />
                <PeerNodeAdd :peer="peer" @nodes="add_node($event)" />
            </template>
            <v-data-table
                :items="model_peer.nodes"
                :headers="headers"
                disable-pagination
                hide-default-footer
                dense
            >
                <template #item.state="{ item }">
                    <v-progress-circular v-if="peerStatusLoading" indeterminate color="primary" size="24" />
                    <div v-else>
                        <span v-if="item.short_code in peerStatus[peer.internal_name]">
                            {{ peerStatus[peer.internal_name][item.short_code].state }}
                        </span>
                        <span v-else>Waiting for provisioning</span>
                    </div>
                </template>
                <template #item.last_change="{ item }">
                    <v-progress-circular v-if="peerStatusLoading" indeterminate color="primary" size="24" />
                    <div v-else>
                        <span v-if="item.short_code in peerStatus[peer.internal_name]">
                            {{ peerStatus[peer.internal_name][item.short_code].last_change | ts_ago }}
                        </span>
                        <span v-else>-</span>
                    </div>
                </template>
                <template #item.remove="{ item }">
                    <PeerNodeDel :peer="peer" :node="item" />
                </template>
            </v-data-table>
        </MyCard>
    </div>
</template>

<script>
import PeerNodeAdd from "@/components/PeerNodeAdd.vue";
import PeerNodeDel from "@/components/PeerNodeDel.vue";
import PeerDelete from "@/components/PeerDelete.vue";
import MyCard from "@/components/MyCard.vue";
import TierLimitReached from "@/components/TierLimitReached.vue";

import { useConstants } from "@/const/const.js";

import { useRouter } from "vue2-helpers/vue-router";

function random(length=12, chars="ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789") {
    var str = "";
    for (let i = 0; i < length; i++) {
        str += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return str;
}

import { ref, reactive, onMounted } from "@vue/composition-api";
import axios from "axios";

export default {
    props: ["peer", "newPeer"],
    components: {
        PeerNodeAdd,
        PeerNodeDel,
        PeerDelete,
        MyCard,
        TierLimitReached,
    },
    setup(props) {
        const router = useRouter();
        const constants = useConstants();

        const isEditable = ref({
            name: false,
            asnLocal: false,
            asnPeer: false,
            bgpPassword: false,
            ip: false,
            nextHopIPv4: false,
            nextHopIPv6: false,
        });
        const messages = ref({
            name: "",
            asnLocal: "",
            asnPeer: "",
            bgpPassword: "",
            ip: "",
            nextHopIPv4: "",
            nextHopIPv6: "",
        });
        const model_peer = reactive({});
        const profiles = ref([]);
        const nodes = ref([]);
        const profile = ref("");
        const peerStatus = ref({});
        const peerStatusLoading = ref(true);
        const profileFilters = ref([]);
        const filtersLoading = ref(false);
        const changed = ref(false);
        const headers = [
            { text: "Node", value: "name", width: "20%" },
            { text: "IP Address", value: "bgp_ip", width: "25%" },
            { text: "State", value: "state", width: "25%" },
            { text: "Last Change", value: "last_change", width: "25%" },
            { text: "Delete", value: "remove", width: "5%", align: "center" },
        ];

        const passwordVisible = ref(false);
        const snackbar = ref(false);

        function setIsEditableFalse() {
            for (var i in isEditable.value) {
                isEditable.value[i] = false;
            }
        }
        function setIsEditableTrue() {
            for (var i in isEditable.value) {
                isEditable.value[i] = true;
            }
        }
        function init_model_peer() {
            model_peer.guid             = !props.newPeer ? props.peer.guid           : undefined;
            model_peer.ip               = !props.newPeer ? props.peer.ip             : undefined;
            model_peer.name             = !props.newPeer ? props.peer.name           : undefined;
            model_peer.asnPeer          = !props.newPeer ? props.peer.asn_peer       : "65000";
            model_peer.asnLocal         = !props.newPeer ? props.peer.asn_local      : "65000";
            model_peer.bgpPassword      = !props.newPeer ? props.peer.bgp_password   : random();
            model_peer.nextHopIPv4      = !props.newPeer ? props.peer.next_hop_ipv4  : constants.defaultIPv4NextHop;
            model_peer.nextHopIPv6      = !props.newPeer ? props.peer.next_hop_ipv6  : constants.defaultIPv6NextHop;
            model_peer.receiveIPv4     = !props.newPeer ? props.peer.receive_ipv4   : true;
            model_peer.receiveIPv6     = !props.newPeer ? props.peer.receive_ipv6   : true;
            model_peer.nodes            = !props.newPeer ? props.peer.nodes          : [];
            profile.value               = !props.newPeer ? props.peer.profile        : {};
        }
        function add_node(new_node) {
            peerStatus.value[props.peer.internal_name][new_node.hostname] = {
                state: "waiting for provisioning",
                last_change: Math.round(Date.now() / 1000),
            };
            props.peer.nodes.push(new_node);
        }
        function revert() {
            init_model_peer();
            changed.value = false;
        }
        function input() {
            changed.value = true;
        }
        function change() {
            if (props.newPeer) {
                // TODO add checking for mandatory fields
                changed.value = true;
            } else {
                setIsEditableFalse();
                changed.value = true;
            }
        }
        function save() {
            const postData = {
                name: model_peer.name,
                ip: model_peer.ip,
                bgp_password: model_peer.bgpPassword,
                receive_ipv4: model_peer.receiveIPv4,
                receive_ipv6: model_peer.receiveIPv6,
                profile_guid: profile.value.guid,
                asn_local: model_peer.asnLocal,
                asn_peer: model_peer.asnPeer,
            };

            if (props.newPeer) {
                axios
                    .post("/api/v1/peers/", postData)
                    .then((response) => {
                        changed.value = false;
                        props.peer = response.data;
                        // TODO show success message in snackbar
                        router.push(`/peers/${response.data.guid}`);
                    })
                    .catch((error) => {
                        if (error.response.status == 405) {
                            snackbar.value = true;
                        }
                        if (error.response.data.error) {
                            messages.value[error.response.data.arg_name] = error.response.data.message;
                        }
                    });
            } else {
                axios.patch(`/api/v1/peers/${props.peer.guid}/`, postData).then(() => {
                    changed.value = false;
                    // TODO show success message in snackbar
                });
            }
        }
        function clickEdit(e) {
            isEditable.value[e.target.name] = true;
        }
        // function formIncomplete() {
        //     return (model_peer.name === "" || model_peer.ip === "" || Object.keys(profile.value).length === 0);
        // }
        onMounted(() => {
            if (!props.newPeer) {
                filtersLoading.value = true;
                axios
                .get(`/api/v1/profiles/${props.peer.profile.guid}`)
                .then((response) => {
                    profileFilters.value = response.data.filters;
                    filtersLoading.value = false;
                });
            }
            axios.get("/api/v1/profiles/").then((response) => {
                profiles.value = response.data;
            });

            init_model_peer();

            if (props.newPeer) {
                setIsEditableTrue();
                return;
            }

            // create a mock peerStatus object to prevent erorrs before it's loaded from API
            peerStatus.value[props.peer.internal_name] = {};
            for (var i in props.peer.nodes) {
                peerStatus.value[props.peer.internal_name][props.peer.nodes[i].hostname] = {};
            }

            const postData = {
                peer_guid: model_peer.guid,
            };
            axios.post("/api/v1/peer_status", postData).then((response) => {
                peerStatus.value = response.data;
                peerStatusLoading.value = false;
            });
        });

        return {
            isEditable,
            messages,
            model_peer,
            profiles,
            nodes,
            profile,
            peerStatus,
            peerStatusLoading,
            profileFilters,
            filtersLoading,
            changed,
            headers,
            passwordVisible,
            snackbar,
            setIsEditableFalse,
            setIsEditableTrue,
            init_model_peer,
            add_node,
            revert,
            change,
            input,
            save,
            clickEdit,
        };
    },
};
</script>
