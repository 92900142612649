var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MyCard',{attrs:{"height":_vm.height,"width":_vm.width},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" BGP Peers "),_c('v-spacer'),_c('ButtonNew',{attrs:{"action":_vm.actionNew}})]},proxy:true}])},[_c('v-data-table',{staticClass:"row-pointer",attrs:{"items":_vm.peers,"headers":_vm.headers,"dense":"","hide-default-footer":_vm.peers.length <= 4,"footer-props":{
            showFirstLastPage: true,
            itemsPerPageOptions: [10, 25, 50, -1],
        },"no-data-text":"You have no BGP peers"},on:{"click:row":_vm.click},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
        var item = ref.item;
return [(item.nodes.length == 0)?_c('div',[_vm._v("(no nodes)")]):(_vm.peerStatusLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"24"}}):_c('div',[(Object.keys(_vm.peerStatus).length > 0)?_c('div',_vm._l((item.nodes),function(node){return _c('div',{key:node.guid},[(node.hostname in _vm.peerStatus[item.internal_name])?_c('div',[_vm._v(" "+_vm._s(_vm.peerStatus[item.internal_name][node.hostname].state)+" ")]):_vm._e()])}),0):_vm._e()])]}},{key:"item.lastChange",fn:function(ref){
        var item = ref.item;
return [(item.nodes.length == 0)?_c('div',[_vm._v("(no nodes)")]):(_vm.peerStatusLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"24"}}):_c('div',[(Object.keys(_vm.peerStatus).length > 0)?_c('div',_vm._l((item.nodes),function(node){return _c('div',{key:node.guid},[(node.hostname in _vm.peerStatus[item.internal_name])?_c('div',[_vm._v(" "+_vm._s(_vm._f("ts_ago")(_vm.peerStatus[item.internal_name][node.hostname].last_change))+" ")]):_vm._e()])}),0):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }