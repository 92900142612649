<template>
    <v-container>
        <v-row no-gutters>
            <v-col cols="12">
                <Peer :peer="peer" :newPeer="newPeer" v-if="Object.keys(peer).length || newPeer" />
                <Peers height="80vh" v-else />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from "@vue/composition-api";
import { useRoute } from "vue2-helpers/vue-router";
import axios from "axios";

import Peer from "@/components/Peer.vue";
import Peers from "@/components/Peers.vue";

export default {
    props: {
        width: String,
    },
    emits: ["breadcrumb"],
    components: {
        Peer,
        Peers,
    },
    // setup(props, context) {
    setup() {
        const route = useRoute();
        const { emit } = getCurrentInstance();

        const peer = ref({});
        const newPeer = ref(false);

        onMounted(() => {
            emit("breadcrumb", [
                {
                    text: "Peers",
                    disabled: false,
                    href: "/peers",
                },
            ]);
            if (route.params.peer_guid) {
                if (route.params.peer_guid === "new") {
                    newPeer.value = true;
                    return;
                }
                let peer_guid = route.params.peer_guid;

                axios.get(`/api/v1/peers/${peer_guid}/`).then((response) => {
                    peer.value = response.data;

                    emit("breadcrumb", [
                        {
                            text: "Peers",
                            disabled: false,
                            href: "/peers",
                        },
                        {
                            text: `${peer.value.name}`,
                            disabled: false,
                            href: `/peers/${peer_guid}`,
                        },
                    ]);
                });
            }
        });

        return {
            peer,
            newPeer,
        };
    },
};
</script>
