<template>
    <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                color="red"
                small
                outlined
                class="text-none"
                :disabled="disabled"
            >
                <v-icon small left>mdi-delete-outline</v-icon>Delete
            </v-btn>
        </template>
        <v-card rounded flat>
            <v-card-title class="text-h6 primary">Delete BGP peer {{ peer.name }} ({{ peer.ip }})?</v-card-title>
            <v-card-text class="mt-8 body-1">
                Are you sure you want to delete this BGP peer? This action cannot be taken back.
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn width="50%" rounded color="red" text @click="doDelete">Yes</v-btn>
                <v-btn width="50%" rounded color="green" text @click="close">No</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { ref } from "@vue/composition-api";
import { useRouter } from "vue2-helpers/vue-router";
import axios from "axios";

export default {
    props: ["peer", "disabled"],
    components: {},
    setup(props) {
        const router = useRouter();

        const dialog = ref(false);
        const errorMessages = ref("");
        const successMessages = ref("");

        function doDelete() {
            axios
                .delete(`/api/v1/peers/${props.peer.guid}/`)
                .then(() => {
                    router.push("/peers");
                })
                .catch((error) => {
                    errorMessages.value = error.response.data.detail;
                });
        }

        function close() {
            dialog.value = false;
            errorMessages.value = "";
            successMessages.value = "";
        }

        return {
            dialog,
            errorMessages,
            successMessages,
            doDelete,
            close,
        };
    },
};
</script>
