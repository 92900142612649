<template>
    <v-dialog v-model="dialog" width="500">
        <TierLimitReached :snackbar="snackbar" />
        <template v-slot:activator="{ on }">
            <!-- <v-icon v-bind="attrs" v-on="on" :color="add_btn_color" @mouseover="add_btn_color='primary'" @mouseleave="add_btn_color=''">mdi-plus-circle-outline</v-icon> -->
            <!-- <ButtonNew :action="on.click"  /> -->
            <ButtonNew :action="on.click" :btnDisabled="peer === 'new'" />
        </template>
        <v-card class="rounded-lg">
            <v-card-title class="text-h6 primary">Add a new BGP peering node</v-card-title>
            <v-card-text>
                <v-select
                    return-object
                    :items="nodes"
                    v-model="node"
                    label="Node"
                    item-text="name"
                    :item-disabled="has_capacity"
                    :error-messages="errorMessages"
                    :success-messages="successMessages"
                    class="my-4"
                    @change="change"
                />
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn width="50%" rounded color="green" text @click="add" :disabled="addDisabled">Add</v-btn>
                <v-btn width="50%" rounded color="red" text @click="close">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ButtonNew from "@/components/ButtonNew.vue";
import TierLimitReached from "@/components/TierLimitReached.vue";

import { ref, onMounted, getCurrentInstance } from "@vue/composition-api";
import axios from "axios";

import { useDialog } from "@/features/dialog.js";

export default {
    props: ["peer"],
    components: {
        ButtonNew,
        TierLimitReached,
    },
    setup(props) {
        const { emit } = getCurrentInstance();
        const { errorMessages, successMessages, dialog } = useDialog();

        const add_btn_color = ref("");
        const nodes = ref([]);
        const node = ref("");
        const addDisabled = ref(true);
        const snackbar = ref("");
        const existingNodeGuids = ref([]);

        function updateExistingNodeGuids() {
            existingNodeGuids.value = [];
            for (var i in props.peer.nodes) {
                existingNodeGuids.value.push(props.peer.nodes[i].guid);
            }
        }

        function filterNodes() {
            nodes.value = nodes.value.filter((x) => !existingNodeGuids.value.includes(x.guid)); 
        }

        onMounted(() => {
            updateExistingNodeGuids();
            axios.get("/api/v1/nodes/").then((response) => {
                nodes.value = response.data;
                filterNodes();
            });
        });


        function close() {
            dialog.value = false;
            node.value = "";
            errorMessages.value = "";
            successMessages.value = "";
        }

        function change() {
            // check for duplicates
            for (var i in props.peer.nodes) {
                if (node.value.guid == props.peer.nodes[i].guid) {
                    errorMessages.value = "This node already exists. Choose a different one.",
                    addDisabled.value = true;
                    return;
                }
            }
            errorMessages.value = "",
            addDisabled.value = false;
        }

        function add() {
            if (node.value === "") return;
            addDisabled.value = true;

            // check for duplicates
            for (var i in props.peer.nodes) {
                if (node.value.guid == props.peer.nodes[i].guid) {
                    errorMessages.value = "This node already exists. Choose a different one."
                    return;
                }
            }

            axios
                .post(`/api/v1/peers/${props.peer.guid}/nodes/${node.value.guid}`)
                .then(() => {
                    successMessages.value = "Node added successfully!";

                    emit("nodes", node.value);
                    updateExistingNodeGuids();
                    filterNodes();

                    setTimeout(() => {
                        close();
                        addDisabled.value = true;
                    }, 2000);
                })
                .catch((error) => {
                    if (error.response.status == 405) {
                        snackbar.value = true;
                        addDisabled.value = false;
                    }
                });
        }

        function has_capacity(item) {
            return !item.has_capacity;
        }

        return {
            add_btn_color,
            nodes,
            node,
            addDisabled,
            snackbar,
            change,
            add,
            dialog,
            close,
            successMessages,
            errorMessages,
            has_capacity,
        };
    },
};
</script>
